import mission from '../../assets/mission.png'

const Mission = ({darkMode}) => {
  return (
    <section>
      <div className={`par-mission ${darkMode === "dark" ? "dark" : "light"}`}>
        <div className='sub-mission allsub'>
          <div className='row mx-0 justify-content-between align-items-center'>
            <div className='col-lg-6 text-center'>
              <img src={mission} alt='group of people' width={'80%'} />
            </div>
            <div className='col-lg-5'>
              <h1 style={{ fontWeight: '700' }}>Our Mission</h1>
              <p style={{ fontWeight: '400' }}>
                To inspire every business owner and innovator on the planet
                that they can build revolutionary products.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission
