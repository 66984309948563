import { Routes, Route, useLocation } from 'react-router-dom'

import './App.css'
import Footer from './Components/Footer/Footer'
import Navbar from './Components/Navbar/Navbar'
import PageNotFound from './Components/PageNotFound/PageNotFound'
import Abouts from './Pages/Abouts'
import HomePage from './Pages/HomePage'
import Products from './Pages/Products'
import Services from './Pages/Services'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useEffect, useState } from 'react'
import Faq from './Components/Faq/Faq'
import Pricing from './Components/Pricing/Pricing'

function App() {
  // dark state
  const [darkMode, setDarkMode] = useState('')
  const setToggles = () => {
    setDarkMode(darkMode === 'dark' ? 'light' : 'dark')
  }

  if (
    localStorage.getItem('darkMode') === null ||
    localStorage.getItem('darkMode') === undefined
  ) {
    localStorage.setItem('darkMode', 'dark')
  }

  useEffect(() => {
    const currentTheme = localStorage.getItem('darkMode')
    if (currentTheme) {
      setDarkMode(currentTheme)
    } else {
      // darkMode is intentionally excluded from the dependency array
      localStorage.setItem('darkMode', darkMode)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode)
  }, [darkMode])

  // function for scrolling to the top when route chnages
  const routepath = useLocation()
  const onTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    onTop()
  }, [routepath])

  // pricing modal
  const [pricing, setPricing] = useState(false)

  const handlePricing =() =>{
      setPricing(!pricing)
  }

  return (
    // className={darkMode ? 'dark' : ''}
    <div>
      <Navbar darkMode={darkMode} setToggles={setToggles} />
        <Pricing pricing={pricing} handlePricing={handlePricing} />
      <Routes>
        <Route exact path='/' element={<HomePage darkMode={darkMode} />} />
        <Route
          exact
          path='/about-us'
          element={<Abouts darkMode={darkMode} />}
        />
        <Route
          exact
          path='/services'
          element={<Services darkMode={darkMode} />}
        />
        <Route
          exact
          path='/products'
          element={<Products darkMode={darkMode} />}
        />
        <Route exact path='/faq' element={<Faq darkMode={darkMode} />} />

        <Route path='/*' element={<PageNotFound darkMode={darkMode} />} />
      </Routes>
      <Footer darkMode={darkMode} handlePricing={handlePricing}/>
    </div>
  )
}

export default App
