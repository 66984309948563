// import Slider from 'react-slick'

import { Carousel } from 'react-bootstrap'
import './Testimonials.css'

const Testimonials = ({darkMode}) => {
  // array of object for the testimonials
  const aims = [
    {
      message:
        '“I have always wanted a website that will capture my art and tell I have always wanted a website that will capture my art and tell my stories the way I want. I shared my concerns with Fireswitch Tech when I needed them to design my websites and I must say they did justice to it. I was involved in all of the processes and my opinion was asked at every junction. I will always recommend them for their professionalism and listening ear. stories the way I want. I shared my concerns with Fireswitch Tech when I needed them to design my websites and I must say they did justice to it. I was involved in all of the processes and my opinion was asked at every junction. I will always recommend them for their professionalism and listening ear.”',
      name: 'Toyosi',
      position: 'MD Netlight Systems',
    },
    {
      message:
        '“Fireswitch Tech comes first among others as an all round software company. A company dedicated to developing local talent, Fireswitch houses the best crop of software development students and interns. As one of the most innovative software companies in Nigeria, Fire switch is at the forefront of blockchain development and other futuristic technologies alien to most tech companies.”',
      name: 'Tolulope',
      position: 'Toluade Photography',
    },
    {
      message:
        '“Fireswitch has been deliberate about being in the lead of innovations for business solutions. With a team dedicated to growing and improving life for businesses and individuals, offerings of this trailblazing company has only just scratched the surface in technologies that will see businesses succeed and lives lived thriving.”',
      name: 'Williams',
      position: 'Administrator Sycamore Church',
    },
    {
      message:
        '“I have always wanted a website that will capture my art and tell I have always wanted a website that will capture my art and tell my stories the way I want. I shared my concerns with Fireswitch Tech when I needed them to design my websites and I must say they did justice to it. I was involved in all of the processes and my opinion was asked at every junction. I will always recommend them for their professionalism and listening ear. stories the way I want. I shared my concerns with Fireswitch Tech when I needed them to design my websites and I must say they did justice to it. I was involved in all of the processes and my opinion was asked at every junction. I will always recommend them for their professionalism and listening ear.”',
      name: 'Toyosi',
      position: 'MD Netlight Systems',
    },
    {
      message:
        '“Fireswitch Tech comes first among others as an all round software company. A company dedicated to developing local talent, Fireswitch houses the best crop of software development students and interns. As one of the most innovative software companies in Nigeria, Fire switch is at the forefront of blockchain development and other futuristic technologies alien to most tech companies.”',
      name: 'Tolulope',
      position: 'Toluade Photography',
    },
    {
      message:
        '“Fireswitch has been deliberate about being in the lead of innovations for business solutions. With a team dedicated to growing and improving life for businesses and individuals, offerings of this trailblazing company has only just scratched the surface in technologies that will see businesses succeed and lives lived thriving.”',
      name: 'Williams',
      position: 'Administrator Sycamore Church',
    },
  ]
  // array of object for the testimonials

  // settings for the slider
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 2,
  //   swipeToSlide: true,
  //   autoplay: true,
  //   speed: 2000,
  //   height: 400,
  //   autoplaySpeed: 4000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         dots: false,
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         infinite: true,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         dots: false,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 567,
  //       settings: {
  //         dots: false,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // }
  // settings for the slider

  return (
    <section id='testimonials'>
      <div
        className={`par-testimonials ${darkMode === 'dark' ? 'dark' : 'light'}`}
      >
        <div className='sub-testimonials allsub'>
          <div className='text-center'>
            <div className='text-center mb-5 pry-bold-text'>
              <h1
                className='h1-responsive text-center'
                style={{ fontWeight: '700' }}
              >
                What our partners are saying
              </h1>
              <p style={{ fontWeight: '400' }}>
                Our team of product, design, and research experts helps people
                like you craft beautifully simple and world-class products.
              </p>
            </div>
          </div>
          <div className='row justify-content-center align-items-center px-0 mx-0 w-100 '>
            <div className='grad'></div>
            <Carousel
              fade
              indicators={false}
              variant={darkMode === 'dark' ? 'dark' : 'light'}
              interval={10000}
              role='listbox'
            >
              {aims.map(({ message, name, position }, i) => (
                <Carousel.Item key={i}>
                  <div className='row m-0 p-0 w-100 d-flex justify-content-center align-items-center'>
                    <div className='col-lg-8'>
                      <div
                        className={`card shadow-none h-100 first py-4 px-3 br-md d-flex align-items-start ${
                          darkMode === 'dark' ? 'test-dark' : 'test-light'
                        }`}
                      >
                        <div className='row mx-0 px-0'>
                          <div className='col-1 starl'>
                            <i className='fas fa-star'></i>
                          </div>
                          <div className='col-1'>
                            <i className='fas fa-star'></i>
                          </div>
                          <div className='col-1'>
                            <i className='fas fa-star'></i>
                          </div>
                          <div className='col-1'>
                            <i className='fas fa-star'></i>
                          </div>
                          <div className='col-1'>
                            <i className='fas fa-star'></i>
                          </div>
                        </div>
                        <p
                          className='text-start mb-4'
                          style={{ fontWeight: '500' }}
                        >
                          {message}
                        </p>
                        <div className='mb-2 card-icon d-flex justify-content-end align-items-end w-100'>
                          <div className='row mx-0 w-100 justify-content-end d-flex align-items-end text-end'>
                            <div className='col-12'>
                              <h6 style={{ fontWeight: '700' }}>
                                <b>{name}</b>
                              </h6>
                            </div>
                            <div className='col-12'>
                              <p
                                style={{
                                  fontWeight: '400',
                                  fontStyle: 'italic',
                                }}
                              >
                                {position}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
            {/* <Slider {...settings} className="px-0">
              {aims.map(({ message, name, position }, i) => {
                return (
                  <div className=' mb-4 col-lg-6 mb-lg-0 h-100' key={i}>
                    <div className={`card shadow-none h-100 first py-4 px-3 br-md cards d-flex align-items-stretch ${darkMode === "dark" ? "test-dark" : "test-light"}`} >
                      <div className='row mx-0 px-0'>
                        <div className='col-1 starl'>
                          <i className='fas fa-star'></i>
                        </div>
                        <div className='col-1'>
                          <i className='fas fa-star'></i>
                        </div>
                        <div className='col-1'>
                          <i className='fas fa-star'></i>
                        </div>
                        <div className='col-1'>
                          <i className='fas fa-star'></i>
                        </div>
                        <div className='col-1'>
                          <i className='fas fa-star'></i>
                        </div>
                      </div>
                      <p
                        className='text-start mb-4'
                        style={{ fontWeight: '500' }}
                      >
                        {message}
                      </p>
                      <div className='mb-2 card-icon d-flex justify-content-end align-items-end'>
                        <div className='row mx-0 w-100 justify-content-end d-flex align-items-end text-end'>
                          <div className='col-12'>
                            <h6 style={{ fontWeight: '700' }}>
                              <b>{name}</b>
                            </h6>
                          </div>
                          <div className='col-12'>
                            <p
                              style={{ fontWeight: '400', fontStyle: 'italic' }}
                            >
                              {position}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
