import temi from '../../assets/yinka.png'
import tim from '../../assets/tim.png'
import olu from '../../assets/olu.png'

const TheTeam = ({darkMode}) => {
  const team = [
    {
      name: 'Olu-Immanuel Adekuoroye',
      position: 'Brand Strategyist',
      facebook: 'https://web.facebook.com/sola.adekuoroye.7',
      linkedin: 'https://www.linkedin.com/in/oluimmanuel/',
      twitter: 'https://twitter.com/OluImmanuel',
      person: olu,
    },
    {
      name: 'Timothy Ayodele',
      position: 'CEO/Lead developer',
      facebook: 'https://web.facebook.com/ayodeletim',
      linkedin: 'https://www.linkedin.com/in/ayodeletim/',
      twitter: 'https://twitter.com/ayodeletim',
      person: tim,
    },
    {
      name: 'Olayinka Ogunyemi',
      position: 'Product Manager',
      facebook: 'https://web.facebook.com/profile.php?id=100006732435203',
      linkedin: 'https://www.linkedin.com/in/ogunyemi-olayinka-516692171/',
      twitter: '',
      person: temi,
    },
    // {
    //   name: 'Temiloluwa Abiodun-Ojo',
    //   position: 'Full stack Developer',
    //   facebook: '',
    //   linkedin: '',
    //   twitter: '',
    //   person: temi,
    // },
    // {
    //   name: 'Timothy Ayodele',
    //   position: 'Lead Developer',
    //   facebook: '',
    //   linkedin: '',
    //   twitter: '',
    //   person: tim,
    // },
    // {
    //   name: 'Olu-immanuel Adekuoroye',
    //   position: 'Brand Strategyist',
    //   facebook: '',
    //   linkedin: '',
    //   twitter: '',
    //   person: olu,
    // },
  ]
  
  return (
    <section>
      <div className={`par-team ${darkMode === "dark" ? "pas-contact-us" : "light"}`}>
        <div className='sub-team allsub'>
          <div className='my-3 text-center'>
            <div className='text-center mb-5 pry-bold-text'>
              <h1
                className='h1-responsive text-center my-4 '
                style={{ fontWeight: '700' }}
              >
                THE TEAM
              </h1>
            </div>
          </div>
          <div className='row mx-0 justify-content-center align-items-center w-100'>
            {team.map(
              (
                { name, position, facebook, linkedin, twitter, person },
                index
              ) => (
                <div
                  className='col-lg-4 temg col-sm-6 text-center '
                  key={index}
                >
                  <div className='mb-3'>
                    <img src={person} alt='' className='teams' />
                  </div>
                  <h5 style={{ fontWeight: '700' }}>{name}</h5>
                  <p style={{ fontWeight: '300', fontStyle: "italic" }}>{position}</p>
                  <div className='row mx-0 justify-content-center align-items-center'>
                    {twitter === null ||
                    twitter === undefined ||
                    twitter.length <= 0 ? (
                      <></>
                    ) : (
                      <div className='col-2'>
                        <a
                          href={twitter}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <Twitter />
                        </a>
                      </div>
                    )}
                    <div className='col-2'>
                      <a
                        href={linkedin}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <LinkedIn />
                      </a>
                    </div>
                    <div className='col-2'>
                      <a
                        href={facebook}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <Facebook />
                      </a>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TheTeam

function Facebook() {
  return (
    <svg
      width='14'
      height='27'
      viewBox='0 0 14 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.0826 15.0728L13.8082 10.2233H9.26941V7.07636C9.26941 5.74943 9.90343 4.45631 11.9364 4.45631H14V0.32767C14 0.32767 12.1272 0 10.3366 0C6.59854 0 4.15525 2.32252 4.15525 6.52718V10.2233H0V15.0728H4.15525V26.7962C5.00116 26.9321 5.8561 27.0002 6.71233 27C7.56856 27.0003 8.42351 26.9321 9.26941 26.7962V15.0728H13.0826Z'
        fill='#001E88'
      />
    </svg>
  )
}

function LinkedIn() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.0409 17.0412H14.0776V12.4004C14.0776 11.2937 14.0578 9.86911 12.5363 9.86911C10.9929 9.86911 10.7567 11.0749 10.7567 12.3198V17.0409H7.79344V7.49747H10.6382V8.80169H10.678C10.9627 8.31489 11.3741 7.91443 11.8684 7.64295C12.3627 7.37147 12.9214 7.23914 13.4849 7.26005C16.4884 7.26005 17.0422 9.23567 17.0422 11.8058L17.0409 17.0412ZM4.44969 6.19302C3.49992 6.19317 2.72984 5.42333 2.72969 4.47356C2.72953 3.5238 3.4993 2.75372 4.44906 2.75356C5.39883 2.75333 6.16891 3.52317 6.16906 4.47294C6.16915 4.92903 5.98805 5.36648 5.6656 5.68906C5.34316 6.01163 4.90578 6.19291 4.44969 6.19302ZM5.93141 17.0413H2.96492V7.49747H5.93133V17.0412L5.93141 17.0413ZM18.5183 0.00145562H1.47586C0.67039 -0.00760688 0.00984375 0.637627 0 1.4431V18.5566C0.00953125 19.3625 0.67 20.0083 1.47578 19.9999H18.5183C19.3258 20.0099 19.9888 19.364 20 18.5566V1.44177C19.9885 0.634737 19.3254 -0.0104194 18.5183 0.000127492'
        fill='#001E88'
      />
    </svg>
  )
}

function Twitter() {
  return (
    <svg
      width='22'
      height='18'
      viewBox='0 0 22 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22 2.06813C21.1762 2.42475 20.3024 2.65883 19.4076 2.7626C20.3396 2.21676 21.0552 1.35241 21.3922 0.322547C20.5062 0.836291 19.5369 1.19826 18.5261 1.3928C17.7027 0.535674 16.5297 0 15.2315 0C12.7388 0 10.7178 1.97475 10.7178 4.41035C10.7178 4.75607 10.7577 5.09264 10.8347 5.41552C7.08348 5.23154 3.7577 3.47571 1.53149 0.807332C1.14305 1.45872 0.920477 2.21643 0.920477 3.0246C0.920477 4.55478 1.71738 5.90468 2.92841 6.69563C2.21164 6.6737 1.51065 6.48453 0.883953 6.14392C0.883695 6.1624 0.883695 6.18087 0.883695 6.19943C0.883695 8.33633 2.43951 10.1189 4.50424 10.5241C3.83959 10.7008 3.14242 10.7266 2.46598 10.5997C3.0403 12.3519 4.70723 13.627 6.68224 13.6627C5.13752 14.8455 3.19129 15.5507 1.07671 15.5507C0.712336 15.5507 0.353117 15.5298 0 15.489C1.99745 16.7404 4.36992 17.4706 6.91883 17.4706C15.221 17.4706 19.7608 10.75 19.7608 4.92184C19.7608 4.73054 19.7565 4.54034 19.7478 4.35123C20.6314 3.72703 21.3941 2.9539 22 2.06813Z'
        fill='#001E88'
      />
    </svg>
  )
}
