import serviceback from '../../assets/serviceback.svg'
import ser1 from '../../assets/ser1.svg'
import ser2 from '../../assets/ser2.svg'
import ser3 from '../../assets/ser3.svg'
import ser4 from '../../assets/ser4.svg'

const Service = ({darkMode}) => {
  return (
    <section>
      <div className='backloor w-100'>
        <img src={serviceback} alt='a multiple lines' width={'100%'} />
      </div>
      <div className={`par-service ${darkMode === "dark" ? "dark" : "light"}`}>
        <div className='sub-service text-center py-5 allsub'>
          <h1
            className='text-center w-responsive mx-auto mb-3'
            style={{ fontWeight: '800' }}
          >
            Here is how we can help bring your ideas to Life
          </h1>
          <p
            className='text-center w-responsive mx-auto mb-5'
            style={{ fontWeight: '400' }}
          >
            We use our skills and experience to solve business problems and
            birth innovative solutions
          </p>
          <img src={ser1} alt='a multiple lines' className='ser1' />
          <img src={ser2} alt='a multiple lines' className='ser2' />
          <img src={ser3} alt='a multiple lines' className='ser3' />
          <img src={ser4} alt='a multiple lines' className='ser4' />
        </div>
      </div>
    </section>
  )
}

export default Service
