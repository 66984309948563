import vision from '../../assets/vision.png'

const Vision = ({darkMode}) => {
  return (
    <section>
      <div className={`par-vision ${darkMode === "dark" ? "dark" : "light"}`}>
        <div className='sub-vision allsub'>
          <div className='row mx-0 justify-content-between align-items-center otherway'>
            <div className='col-lg-5'>
              <h1 style={{ fontWeight: '700' }}>Our Vision</h1>
              <p style={{ fontWeight: '400' }}>
                We envision a world where digital products that solve real
                problems can be created with ease, at record speed and to
                specification.
              </p>
            </div>
            <div className='col-lg-6 text-center'>
              <img src={vision} alt='group of people' width={'80%'} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Vision
