import './Pricing.css'

import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useState } from 'react'

const Pricing = ({ pricing, handlePricing }) => {
  const [value, setValue] = useState('')

  return (
    <>
      <div className={`modal-background ${pricing ? 'd-block' : 'd-none'}`}>
        <div className='modals row mx-0' style={{ padding: '5rem 0' }}>
          <div className='col-lg-5 col-md-8'>
            <div className='w-100 show-white h-100 p-3 rounded'>
              <span class='close' onClick={handlePricing}>
                &times;
              </span>
              <h1>Send us a message</h1>
              <form action='' className='w-100'>
                <input
                  type='text'
                  className='form-control mb-4'
                  placeholder='First Name'
                />
                <input
                  type='text'
                  className='form-control mb-4'
                  placeholder='Last Name'
                />
                <input
                  type='email'
                  className='form-control mb-4'
                  placeholder='Email'
                />
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry='NG'
                  value={value}
                  onChange={setValue}
                  name='pword'
                  className='form-control d-flex phonein mb-4 justify-content-center align-items-center'
                />
                <select
                  class='form-select mb-4'
                  aria-label='Default select example'
                >
                  <option selected>What services do you need?</option>
                  <option value='1'>One</option>
                  <option value='2'>Two</option>
                  <option value='3'>Three</option>
                </select>
                <textarea
                  name=''
                  id=''
                  cols='5'
                  rows='10'
                  placeholder='Additional Comments.'
                  className='form-control'
                ></textarea>

                <button className='btn w-100' style={{}}>
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pricing
