import Partners from '../Components/Partners/Partners'
import Portfolio from '../Components/Products/Portfolio'
import Product from '../Components/Products/Product'

import '../Components/Products/Products.css'

const Products = ({darkMode}) => {
  return (
    <div style={{ marginTop: '10vh' }}>
      <Product darkMode={darkMode} />
      <Partners darkMode={darkMode} />
      <Portfolio darkMode={darkMode} />
    </div>
  )
}

export default Products
