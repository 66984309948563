import About from '../Components/Abouts/About'
import '../Components/Abouts/Abouts.css'
import ContactUs from '../Components/Abouts/ContactUs'
import Mission from '../Components/Abouts/Mission'
import TheTeam from '../Components/Abouts/TheTeam'
import Vision from '../Components/Abouts/Vision'

const Abouts = ({darkMode}) => {
  return (
    <div style={{ marginTop: '10vh' }}>
      <About darkMode={darkMode} />
      <Mission darkMode={darkMode} />
      <Vision darkMode={darkMode} />
      <TheTeam darkMode={darkMode} />
      <ContactUs darkMode={darkMode} />
    </div>
  )
}

export default Abouts
