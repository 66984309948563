import Courses from '../Components/HomePage/Courses'
import Home from '../Components/HomePage/Home'
import Numbers from '../Components/HomePage/Numbers'
import TeamWork from '../Components/HomePage/TeamWork'

import '../Components/HomePage/HomePage.css'
import Testimonials from '../Components/Testimonials/Testimonials'
import Partners from '../Components/Partners/Partners'

const HomePage = ({darkMode}) => {
  return (
    <div style={{ marginTop: '10vh' }}>
      <Home darkMode={darkMode} />
      <Partners darkMode={darkMode} />
      <Numbers darkMode={darkMode} />
      <Courses darkMode={darkMode} />
      <Testimonials darkMode={darkMode} />
      <TeamWork darkMode={darkMode} />
    </div>
  )
}

export default HomePage
