import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import fireswitchlogo from '../../assets/fireswitchlogo.svg'
import './Navbar.css'

const Navbar = ({ darkMode, setToggles }) => {
  // light and dark mode
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff'
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }))

  // function for nav responsiveness

  const [toggle, setToggle] = useState(false)

  let activeStyle = {
    color: '#3662FD',
    // padding: '4rem',
    fontWeight: '600',
  }
  let activeStyles = {
    // color: 'var(--white-color)',
    fontWeight: '00 !important',
  }

  const openNav = () => {
    setToggle(true)
    document.getElementById('mySidebar').style.width = '100%'
  }
  const closeNav = () => {
    setToggle(false)
    document.getElementById('mySidebar').style.width = '0px'
  }

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg navbar-light w-100 px-0 d-flex  justify-content-center align-items-center  mx-0 fixed-top ${
          darkMode === 'dark' ? 'dark' : 'light'
        }`}
        id='times'
      >
        <div className='container app__navbar-logo mx-0 d-flex justify-content-between align-items-center px-0'>
          <Link className='navbar-brand ' to='/'>
            <img
              src={fireswitchlogo}
              alt='Fireswitch logo'
              className={darkMode === 'dark' ? '' : 'dark-navimg'}
            />
          </Link>
          <div>
            <FormGroup className='mobile-toggle'>
              <FormControlLabel
                onClick={setToggles}
                control={
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    checked={darkMode === 'dark' ? true : false}
                  />
                }
              />
            </FormGroup>
            {toggle ? (
              // href='javascript:void(0)'
              // className='closebtn'
              // onClick={closeNav}

              <i
                className={`fas fa-times nav-btn ${
                  darkMode === 'dark' ? 'dark-side' : 'light-side'
                }`}
                onClick={closeNav}
              ></i>
            ) : (
              <i
                className='fas fa-bars nav-btn navbar-toggler'
                onClick={openNav}
              ></i>
            )}
          </div>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav text-end justify-content-center align-items-center'>
              <li className='nav-item'>
                <NavLink
                  to='/about-us'
                  style={({ isActive }) =>
                    isActive ? activeStyle : activeStyles
                  }
                  className={`nav-link ${
                    darkMode === 'dark' ? 'light-nav-link' : 'dark-nav-link'
                  }`}
                  end
                >
                  About us
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  className={`nav-link ${
                    darkMode === 'dark' ? 'light-nav-link' : 'dark-nav-link'
                  }`}
                  to='/services'
                  style={({ isActive }) =>
                    isActive ? activeStyle : activeStyles
                  }
                >
                  Services
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  className={`nav-link ${
                    darkMode === 'dark' ? 'light-nav-link' : 'dark-nav-link'
                  }`}
                  to='/products'
                  style={({ isActive }) =>
                    isActive ? activeStyle : activeStyles
                  }
                >
                  Products
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link'>
                  <FormGroup>
                    <FormControlLabel
                      onClick={setToggles}
                      control={
                        <MaterialUISwitch
                          sx={{ m: 1 }}
                          checked={darkMode === 'dark' ? true : false}
                        />
                      }
                    />
                  </FormGroup>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div
        id='mySidebar'
        className='sidebar'
        style={{ backgroundColor: darkMode === 'dark' ? '#000' : '#fff' }}
      >
        <NavLink
          to='/about-us'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyle : activeStyles)}
          end
          className={` ${darkMode === 'dark' ? 'dark-side' : 'light-side'}`}
        >
          About us
        </NavLink>
        <br />
        <NavLink
          to='/services'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyle : activeStyles)}
          className={` ${darkMode === 'dark' ? 'dark-side' : 'light-side'}`}
        >
          Services
        </NavLink>
        <br />
        <NavLink
          to='/products'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyle : activeStyles)}
          className={` ${darkMode === 'dark' ? 'dark-side' : 'light-side'}`}
        >
          Products
        </NavLink>
        <br />
        <NavLink
          to='/faq'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyle : activeStyles)}
          className={` ${darkMode === 'dark' ? 'dark-side' : 'light-side'}`}
        >
          FAQ
        </NavLink>
        <br />
        <NavLink
          to='/'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyle : activeStyles)}
          className={` ${darkMode === 'dark' ? 'dark-side' : 'light-side'}`}
        >
          Blog
        </NavLink>
        <br />
      </div>
    </div>
  )
}

export default Navbar
