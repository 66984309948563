import contact from '../../assets/contact.svg'

const ContactUs = ({darkMode}) => {
  return (
    <section>
      <div
        className={`par-contactus ${
          darkMode === 'dark' ? 'pas-contact-us' : 'light'
        }`}
      >
        <div className='sub-contactus allsub'>
          {' '}
          <div className='mt-1 mb-5  text-center'>
            <div className='text-center mb-5 pry-bold-text'>
              <h1
                className='h1-responsive text-center my-4 '
                style={{ fontWeight: '700' }}
              >
                Contact Us
              </h1>
            </div>
          </div>
          <div className='row justify-content-center align-items-center mx-0 w-100 allsub'>
            <div className='col-lg-6'>
              <img src={contact} alt='contact' width={'100%'} />
            </div>
            <div className='col-lg-6'>
              <div className='row mx-0 justify-content-center'>
                <div className='col-12 mb-4'>
                  <div className='card h-100 bg-transparent h-100 text-center'>
                    <a
                      href='mailto:hello@fireswitch.tech'
                      className='text-decoration-none mb-2'
                    >
                      <Email />
                    </a>
                    <p style={{ fontWeight: '400' }}>
                      <a
                        href='mailto:hello@fireswitch.tech'
                        style={{ color: darkMode === 'dark' ? '#fff' : '#000' }}
                        className='text-decoration-none'
                      >
                        hello@fireswitch.tech
                      </a>
                    </p>
                  </div>
                </div>
                <div className='col-12 mb-4'>
                  <div className='card h-100 bg-transparent h-100 text-center'>
                    <a
                      href='tel:+234 803 7837 313'
                      className='text-decoration-none mb-2'
                    >
                      <Call />
                    </a>
                    <p style={{ fontWeight: '400' }}>
                      <a
                        href='tel:+234 803 7837 313'
                        style={{ color: darkMode === 'dark' ? '#fff' : '#000' }}
                        className='text-decoration-none'
                      >
                        +234 803 7837 313
                      </a>
                    </p>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='card h-100 bg-transparent h-100 text-center'>
                    <a
                      href='mailto:hello@fireswitch.tech'
                      className='text-decoration-none mb-2'
                    >
                      <Venue />
                    </a>
                    <p className='text-wrap' style={{ fontWeight: '400' }}>
                      SHEDA HOUSE, NO 12, OROGUN ROAD, IBADAN, OYO STATE
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs

function Call() {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.0611782 2.44289C0.0437746 2.04327 0.162477 1.70445 0.417288 1.42643C0.6721 1.14842 0.999317 1.00071 1.39894 0.983303L6.79383 0.748354C7.10464 0.734818 7.38118 0.822869 7.62344 1.01251C7.86481 1.20219 8.02556 1.45098 8.1057 1.7589L9.23829 6.31394C9.29623 6.62282 9.28249 6.94061 9.19707 7.2673C9.11072 7.59314 8.97199 7.86032 8.78088 8.06883L5.78684 11.3355C6.88158 12.9783 8.15899 14.4744 9.61908 15.8237C11.0791 17.1721 12.6287 18.2778 14.2678 19.1406L17.3907 15.7682C17.5818 15.5597 17.804 15.4219 18.0573 15.3548C18.3098 15.2886 18.5924 15.271 18.9052 15.3019L23.6051 15.9647C23.9179 15.9955 24.1795 16.1287 24.3899 16.3642C24.6004 16.5997 24.7124 16.8729 24.7259 17.1837L24.9608 22.5786C24.9782 22.9782 24.8595 23.317 24.6047 23.5951C24.3499 23.8731 24.0227 24.0208 23.6231 24.0382C20.7591 24.1629 17.9397 23.646 15.1649 22.4874C12.3893 21.3297 9.90696 19.7473 7.718 17.7402C5.52819 15.7341 3.73602 13.3992 2.34147 10.7355C0.946002 8.07106 0.185904 5.30684 0.0611782 2.44289ZM16.8538 20.2958C17.7361 20.6355 18.6256 20.886 19.5224 21.0471C20.4191 21.2082 21.3251 21.3245 22.2401 21.3959L22.1111 18.432L18.956 18.0022L16.8538 20.2958ZM4.4126 8.893L6.54665 6.56462L5.77759 3.46181L2.84703 3.58943C2.95328 4.49678 3.137 5.39542 3.39819 6.28534C3.65933 7.17437 3.99747 8.0436 4.4126 8.893Z'
        fill='#001E88'
      />
    </svg>
  )
}

function Email() {
  return (
    <svg
      width='32'
      height='26'
      viewBox='0 0 32 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.2 25.6C2.32 25.6 1.56693 25.2869 0.9408 24.6608C0.3136 24.0336 0 23.28 0 22.4V3.2C0 2.32 0.3136 1.56693 0.9408 0.9408C1.56693 0.3136 2.32 0 3.2 0H28.8C29.68 0 30.4336 0.3136 31.0608 0.9408C31.6869 1.56693 32 2.32 32 3.2V22.4C32 23.28 31.6869 24.0336 31.0608 24.6608C30.4336 25.2869 29.68 25.6 28.8 25.6H3.2ZM28.8 6.4L16.84 13.88C16.7067 13.96 16.5664 14.0197 16.4192 14.0592C16.2731 14.0997 16.1333 14.12 16 14.12C15.8667 14.12 15.7269 14.0997 15.5808 14.0592C15.4336 14.0197 15.2933 13.96 15.16 13.88L3.2 6.4V22.4H28.8V6.4ZM16 11.2L28.8 3.2H3.2L16 11.2ZM3.2 6.4V6.8V4.44V4.48V3.2V4.48V4.4592V6.8V6.4V22.4V6.4Z'
        fill='#001E88'
      />
    </svg>
  )
}

function Venue() {
  return (
    <svg
      width='24'
      height='30'
      viewBox='0 0 24 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 15C12.825 15 13.5315 14.706 14.1195 14.118C14.7065 13.531 15 12.825 15 12C15 11.175 14.7065 10.4685 14.1195 9.8805C13.5315 9.2935 12.825 9 12 9C11.175 9 10.469 9.2935 9.882 9.8805C9.294 10.4685 9 11.175 9 12C9 12.825 9.294 13.531 9.882 14.118C10.469 14.706 11.175 15 12 15ZM12 29.4375C11.8 29.4375 11.6 29.4 11.4 29.325C11.2 29.25 11.025 29.15 10.875 29.025C7.225 25.8 4.5 22.8065 2.7 20.0445C0.9 17.2815 0 14.7 0 12.3C0 8.55 1.2065 5.5625 3.6195 3.3375C6.0315 1.1125 8.825 0 12 0C15.175 0 17.9685 1.1125 20.3805 3.3375C22.7935 5.5625 24 8.55 24 12.3C24 14.7 23.1 17.2815 21.3 20.0445C19.5 22.8065 16.775 25.8 13.125 29.025C12.975 29.15 12.8 29.25 12.6 29.325C12.4 29.4 12.2 29.4375 12 29.4375Z'
        fill='#001E88'
      />
    </svg>
  )
}
