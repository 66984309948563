import product from '../../assets/product.svg'

const Product = ({darkMode}) => {
  return (
    <section>
      <div className={`par-product ${darkMode === "dark" ? "dark" : "light"}`}>
        <div className='sub-product allsub'>
          <div className='row mx-0 justify-content-center align-items-center'>
            <div className='col-lg-6'>
              <img src={product} alt='product' width={'100%'} />
            </div>
            <div className='col-lg-6'>
              <h1
                className='lh-lg text-center text-lg-start'
                style={{ fontWeight: '700' }}
              >
                Our Products
              </h1>
              <p
                className='lh-lg text-center text-lg-start'
                style={{ fontWeight: '400' }}
              >
                Discover the innovative solutions we have developed to address
                the needs of businesses in various industries. Our company has a
                wealth of experience in the logistics, inventory and accounting,
                ecommerce, blockchain, and business communication sectors, and
                we have used this expertise to create products that deliver real
                value to users.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Product
