import wave from '../../assets/wave.svg'

const Courses = ({darkMode}) => {
  return (
    <section>
      <div className={`par-courses ${darkMode === 'dark' ? 'dark' : 'light'}`}>
        <div className='sub-courses'>
          <div className='my-1 text-center'>
            <div className='text-center mb-5 pry-bold-text'>
              <h1
                className='h1-responsive text-center my-4 '
                style={{ fontWeight: '700' }}
              >
                How Fireswitch Tech gets it done
              </h1>

              <p
                className='text-center w-responsive mx-auto mb-5'
                style={{ fontWeight: '400' }}
              >
                Our team of product, design, and research experts helps people
                like you craft beautifully simple and world-class products.
              </p>
            </div>
          </div>
          <div className='row mx-0 w-100 text-center mt-3'>
            <div className='col-md-6 col-lg-4 borderlineb borall'>
              <div className='card shadow-none h-100 bg-transparent py-5 px-3 br-md cards d-flex justify-content-center align-items-center'>
                <div className='mb-3 mx-2 card-icon'>
                  <Headphone />
                </div>
                <h5 className='my-4'>Consulting</h5>
                <p className='text-center'>
                  Your business, goals, pain spots and priorities are understood
                  at this point. Multiple brainstorming sessions sketch out
                  existing workflows and future goals.
                </p>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 borderlinelr borderlineb borall'>
              <div className='card shadow-none h-100 bg-transparent py-5 px-3 br-md cards d-flex justify-content-center align-items-center'>
                <div className='mb-3 mx-2 card-icon'>
                  <Chart />
                </div>
                <h5 className=' my-4'>Strategy and design</h5>
                <p className='text-center'>
                  After gathering your requirements in a whiteboard session, we
                  start developing, creating, and testing your platform or
                  product with real users.
                </p>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 borderlineb borall'>
              <div className='card shadow-none h-100 bg-transparent py-5 px-3 br-md cards d-flex justify-content-center align-items-center'>
                <div className='mb-3 mx-2 card-icon'>
                  <Board />
                </div>
                <h5 className=' my-4'>Product development</h5>
                <p className='text-center'>
                  This step involves programming and coding depending on your
                  designs. Alpha, Beta, and Release are the stages of our
                  development lifecycle.
                </p>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 borall'>
              <div className='card shadow-none h-100 bg-transparent py-5 px-3 br-md cards d-flex justify-content-center align-items-center'>
                <div className='mb-3 mx-2 card-icon'>
                  <Archive />
                </div>
                <h5 className=' my-4'>Quality control</h5>
                <p className='text-center'>
                  Each version of your product or platform undergoes a
                  data-derived validation and in-depth QA testing to assure
                  market readiness.
                </p>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 borderlinelr boralls'>
              <div className='card shadow-none h-100 bg-transparent py-5 px-3 br-md cards d-flex justify-content-center align-items-center'>
                <div className='mb-3 mx-2 card-icon'>
                  <Folder />
                </div>
                <h5 className=' my-4'>Launch and maintenance</h5>
                <p className='text-center'>
                  Each version of your product or platform undergoes a
                  data-derived validation and in-depth QA testing to assure
                  market readiness.
                </p>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 '>
              <div className='card shadow-none h-100 bg-transparent py-5 px-3 br-md cards d-flex justify-content-center align-items-center'>
                <div className='mb-3 mx-2 card-icon'>
                  <CircleChart />
                </div>
                <h5 className=' my-4'>Support and upgrades</h5>
                <p className='text-center'>
                  We maintain and optimise products and platforms, launch market
                  strategies, and give real/fix support to ensure continual
                  improvement.
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src={wave} alt='wave' />
      </div>
    </section>
  )
}

export default Courses

function Headphone() {
  return (
    <svg
      width='46'
      height='46'
      viewBox='0 0 46 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M45 29V23C45 10.85 35.15 1 23 1C10.85 1 1 10.85 1 23V29'
        fill='#E0E0E0'
      />
      <path
        d='M45 29V23C45 10.85 35.15 1 23 1C10.85 1 1 10.85 1 23V29'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 29H1V41C1 43.209 2.791 45 5 45H13V29Z'
        fill='#E0E0E0'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45 29H33V45H41C43.209 45 45 43.209 45 41V29Z'
        fill='#E0E0E0'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

function Chart() {
  return (
    <svg
      width='46'
      height='46'
      viewBox='0 0 46 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27 27H19V45H27V27Z'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 37H1V45H9V37Z'
        fill='#D4D4D8'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45 17H37V45H45V17Z'
        fill='#D4D4D8'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 17L15 7L23 15L37 1'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28 1H37V10'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

function Board() {
  return (
    <svg
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M41 1H1V41H41V1Z'
        stroke='#F2F2F2'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 7H7V20H18V7Z'
        stroke='#F2F2F2'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 26H7V35H18V26Z'
        stroke='#F2F2F2'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35 7H24V35H35V7Z'
        fill='#D4D4D8'
        stroke='#F2F2F2'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

function Archive() {
  return (
    <svg
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.66667 25H6C3.23858 25 1 27.2386 1 30V37C1 39.7614 3.23858 42 6 42H36C38.7614 42 41 39.7614 41 37V30C41 27.2386 38.7614 25 36 25H31.8333C30.2685 25 29 26.2685 29 27.8333C29 29.3981 27.7315 30.6667 26.1667 30.6667H15.3333C13.7685 30.6667 12.5 29.3981 12.5 27.8333C12.5 26.2685 11.2315 25 9.66667 25Z'
        fill='#D4D4D8'
      />
      <path
        d='M9 9H33'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 17H33'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 25H13V31H29V25H41'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37 1H5C2.79086 1 1 2.79086 1 5V37C1 39.2091 2.79086 41 5 41H37C39.2091 41 41 39.2091 41 37V5C41 2.79086 39.2091 1 37 1Z'
        stroke='#E0E0E0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

function Folder() {
  return (
    <svg
      width='46'
      height='42'
      viewBox='0 0 46 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M41 41H5C3.93913 41 2.92172 40.5786 2.17157 39.8284C1.42143 39.0783 1 38.0609 1 37V1H17L22 9H45V37C45 38.0609 44.5786 39.0783 43.8284 39.8284C43.0783 40.5786 42.0609 41 41 41Z'
        stroke='#BDBDBD'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

function CircleChart() {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25 7C34.941 7 43 15.059 43 25C43 34.941 34.941 43 25 43C15.059 43 7 34.941 7 25'
        stroke='#BDBDBD'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 1C9.059 1 1 9.059 1 19H19V1Z'
        fill='#D4D4D8'
        stroke='#BDBDBD'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
