import "./Partners.css"

import Slider from 'react-slick'

import renigo from '../../assets/renigo.png'
import renicloud from '../../assets/renicloud.png'
import renipay from '../../assets/renipay.png'
import reniaccount from '../../assets/reniaccount.png'
import renistore from '../../assets/renistore.png'
import fulazo from '../../assets/fulazo.png'
import eclipse from '../../assets/eclipse.png'
import heartstories from '../../assets/heartstories.png'
import sheda from '../../assets/sheda.png'
import solarcredit from '../../assets/solarcredit.png'
// import { useEffect, useState } from 'react'


const Partners = ({darkMode}) => {
  // settings for the slider
  const settings = {
    dots: false,
    arrows: false,
    lazyLoad: true,
    infinite: true,
    slidesToShow: 4,
    swipeToSlide: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 350,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  }

  // array of images
  const logo = [
    {
      logo: renistore,
      sublogo: "renistore",
    },
    {
      logo: fulazo,
      sublogo: "fulazo",
    },
    {
      logo: renicloud,
      sublogo: "renicloud",
    },
    {
      logo: eclipse,
      sublogo: "eclipse",
    },
    {
      logo: renipay,
      sublogo: "renipay",
    },
    {
      logo: heartstories,
      sublogo: "heartstories",
    },
    {
      logo: reniaccount,
      sublogo: "reniaccount",
    },
    {
      logo: sheda,
      sublogo: "sheda",
    },
    {
      logo: renigo,
      sublogo: "renigo",
    },
    {
      logo: solarcredit,
      sublogo: "solarcredit",
    },
  ]
  // settings for the slider

  //   read up number
 // Set the initial number to 0
  // const [number, setNumber] = useState(0);

  // // Use the useEffect hook to run the code when the component is mounted
  // useEffect(() => {
  //   // Set the interval at which the numbers are displayed
  //   const interval = setInterval(() => {
  //     // Increment the number by 1
  //     setNumber(number + 1);

  //     // Check if the number is 1000
  //     if (number === 1000) {
  //       // If it is, clear the interval to stop the loop
  //       clearInterval(interval);
  //     }else{
  //             setNumber(number + 1)
  //     }
  //   }, 10);
  // }, []);

  return (
    <section>
      <div className={`par-partners ${darkMode === 'dark' ? 'dark' : 'light'}`}>
        <div className='sub-partners allsub'>
          <div className='row mx-0 justify-content-center w-100'>
            <Slider {...settings}>
              <div className='col-3'>
                <h5 style={{ color: '#A1A1AA', fontSize: "16px" }} className='text-nowrap'>
                  1000+ brands trust us
                </h5>
              </div>
              {logo?.map(({ logo, sublogo }, index) => (
                <div className='col-3' key={index}>
                  <img src={logo} alt={sublogo} />
                </div>
              ))}
              {/* <div className='col-3'>
                <img src={squarestone} alt='squarestone' width={'100%'} />
              </div>
              <div className='col-3'>
                <img src={martino} alt='martino' width={'100%'} />
              </div>
              <div className='col-3'>
                <img src={waverio} alt='waverio' width={'100%'} />
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partners

