import numbers from "../../assets/numbers.svg"


const Numbers = ({darkMode}) => {
  return (
    <section>
      <div className={`par-numbers ${darkMode === 'dark' ? 'dark' : 'light'}`}>
        <div className='sub-numbers allsub'>
          <div className='row mx-0 px-0 justify-content-center align-items-center otherway'>
            <div className='col-lg-6'>
              <h1>
                We create Bespoke Software Development from Conception to
                Delivery
              </h1>
              <p>
                Our team of expert software developers creates bespoke software
                products, apps and operational systems for SMEs, enterprises,
                not-for-profit, government and funded start-ups.
              </p>
            </div>
            <div className='col-lg-6'>
              <img src={numbers} alt='group of people' width={'100%'} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Numbers
