import Service from '../Components/Services/Service'
import WeDo from '../Components/Services/WeDo'


import '../Components/Services/Services.css'
import Testimonials from '../Components/Testimonials/Testimonials'
import Partners from '../Components/Partners/Partners'

const Services = ({darkMode}) => {
  return (
    <div style={{ marginTop: '10vh' }}>
      <Service darkMode={darkMode} />
      <Partners darkMode={darkMode} />
      <WeDo darkMode={darkMode} />
      <Testimonials darkMode={darkMode} />
    </div>
  )
}

export default Services
