import { useState } from 'react'
import './Faq.css'

const Faq = () => {
  const items = [
    {
      title: 'What types of businesses do you work with?',
      content:
        'We work with businesses of all sizes, from startups to established companies across various industries.',
    },
    {
      title: 'Can you provide examples of previous work?',
      content: 'Yes, we can provide examples of our previous work upon request',
    },
    {
      title:
        'What sets your company apart from other web design and marketing agencies?',
      content:
        'We prioritize a client-centric approach and strive to understand your business needs and goals to deliver customized solutions.',
    },
    {
      title: 'What is your web design and development process?',
      content:
        'Our web design and development process involves understanding your business needs, creating wireframes and mockups, designing and developing the website, and testing it thoroughly before launch.',
    },
    {
      title: 'How long does it take to build a website?',
      content:
        'The timeline for building a website varies based on the complexity of the project. We provide a detailed timeline in the proposal after discussing your project requirements.',
    },
    {
      title: 'Can you build custom web applications?',
      content:
        'Yes, we can build custom web applications based on your business needs.',
    },
    {
      title: 'Do you offer website maintenance and support?',
      content:
        'Yes, we offer website maintenance and support to ensure your website runs smoothly and stays up-to-date.',
    },
    {
      title: 'Do you design mobile responsive websites?',
      content:
        'Yes, we design mobile responsive websites that look great and function well on all devices.',
    },
    {
      title: 'Can you help with website hosting and domain registration',
      content:
        'Yes, we can help with website hosting and domain registration. We recommend reliable hosting providers and can guide you through the process of registering a domain name.',
    },
    {
      title: 'What is your branding process?',
      content:
        'Our branding process involves understanding your business and target audience, developing a unique brand identity, and creating brand guidelines to ensure consistency across all marketing materials.',
    },
    {
      title: 'Can you design a logo for my company?',
      content:
        'Yes, we can design a logo that represents your company and resonates with your target audience.',
    },
    {
      title: 'Do you offer brand strategy consulting?',
      content:
        "Yes, we offer brand strategy consulting to help you define your brand's unique value proposition and messaging.",
    },
    {
      title: 'How long does it take to design a brand identity?',
      content:
        'The timeline for designing a brand identity varies based on the scope of the project. We provide a detailed timeline in the proposal after discussing your project requirements.',
    },
    {
      title: 'What digital marketing services do you offer?',
      content:
        'We offer a range of digital marketing services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, email marketing, and content marketing.',
    },
    {
      title: 'Can you help with search engine optimization (SEO)?',
      content:
        "Yes, we can help with SEO to improve your website's visibility and search engine rankings.",
    },
    {
      title: 'Can you create and manage social media accounts?',
      content:
        "Yes, we can create and manage social media accounts to increase your brand's online presence and engagement with your audience.",
    },
    {
      title:
        'What metrics do you use to track the success of digital marketing campaigns?',
      content:
        'We track metrics such as website traffic, leads, and conversions to measure the success of digital marketing campaigns.',
    },
    {
      title: 'How do you optimize websites for conversions?',
      content:
        'We optimize websites for conversions by using persuasive copy, clear calls-to-action, and user-friendly design.',
    },
    {
      title: 'What is your product design process?',
      content:
        'Our product design process involves understanding user needs, ideation and brainstorming, creating prototypes, and testing and iterating on the design until it meets user needs.',
    },
    {
      title: 'Can you design physical products as well as digital products?',
      content:
        'Yes, we can design physical products such as consumer electronics, wearable, and home appliances, as well as digital products such as mobile apps and software applications.',
    },
    {
      title: 'How long does it take to design a product?',
      content:
        'The timeline for designing a product varies based on the complexity of the project. We provide a detailed timeline in the proposal after discussing your project requirements.',
    },
    {
      title: 'Can you provide prototyping services?',
      content:
        'Yes, we provide prototyping services to create physical and digital prototypes to test and validate the design.',
    },
    {
      title: 'How do you ensure that the design meets user needs?',
      content:
        'We conduct user research and usability testing to ensure that the design meets user needs and expectations.',
    },
    {
      title: 'Can you provide design for manufacturing services?',
      content:
        'Yes, we provide design for manufacturing services to ensure that the product can be produced efficiently and cost-effectively.',
    },
    {
      title:
        'How do you stay up-to-date on product design trends and technologies?',
      content:
        'We stay up-to-date on product design trends and technologies by attending industry conferences, conducting research, and collaborating with experts in the field.',
    },
  ]

  //  usestate and function to open and close
  const [openSections, setOpenSections] = useState(
    items.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
  )

  const handleClick = (index) => {
    setOpenSections({
      ...openSections,
      [index]: !openSections[index],
    })
  }

  return (
    <section>
      <div className='par-coursecurriculum'>
        <div className='sub-coursecurriculum allsub'>
          <div className='mb-2 mt-5 text-center'>
            <div className='text-center mb-5 pry-bold-text'>
              {/* <h1 className='h1-responsive text-center my-4 '>{event}</h1> */}

              {/* <h4 className='text-center w-resh4onsive mx-auto mb-5'>
                {event_tag}
              </h4> */}
            </div>
          </div>
          <div className='row mx-0 faqaccor mt-1 align-items-start justify-content-center align-items-center'>
            {items.map(({ title, content }, index) => (
              <div key={title} className='mb-1 px-0'>
                <div
                  //   className='d-flex gap-3 points mb-1 align-items-start'
                  className={`d-flex gap-3 justify-content-between align-items-start ${
                    openSections[index] ? 'points' : 'pointss'
                  }`}
                  onClick={() => handleClick(index)}
                >
                  {/* <h6>{index + 1}</h6> */}
                  <h5 className='text-start w-100 mb-0 mx-sm-4 mx-2'>
                    <b>{title}</b>
                  </h5>
                  <p
                    className={`text-center align-self-end mb-0 ${
                      openSections[index] ? 'rotc' : 'notc'
                    }`}
                  >
                    <i className='fa-solid fa-chevron-down'></i>
                  </p>
                </div>
                <div
                  style={{ display: openSections[index] ? 'none' : 'flex' }}
                  className='gap-sm-3 contcurri'
                >
                  {/* <div style={{ visibility: 'hidden' }}>
                    <i className='fa-solid fa-chevron-down'></i>
                  </div> */}
                  <div className='row w-100 financial-footer gap-3'>
                    <div className='d-grid gap-4'>
                      <p className='mb-0'>{content}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq
