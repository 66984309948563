import renistores from '../../assets/renistores.png'
import reniaccounts from '../../assets/reniaccounts.png'
import shedahouses from '../../assets/shedahouses.png'
import solar360s from '../../assets/solar360s.png'
import solarcredits from '../../assets/solarcredits.png'
import spillets from '../../assets/spillets.png'
import afcares from '../../assets/afcares.png'
import renitrusts from '../../assets/renitrusts.jpg'
import fulazos from '../../assets/fulazos.png'
import hotelofflines from '../../assets/hotelofflines.png'
import jlts from '../../assets/jlts.png'

const Portfolio = ({darkMode}) => {
  
  const product = [
    {
      brand: 'ReniAccount',
      writeup:
        'Reniaccount is an accounting and inventory software! This powerful tool allows user to easily track and manage your financial transactions and inventory levels. With our user-friendly interface and advanced features, you can streamline your accounting and inventory processes, saving time and increasing efficiency. Our software integrates with a variety of platforms and can be accessed from any device with an internet connection. ',
      lead: 'https://reniaccounts.com',
      imgs: reniaccounts,
    },
    {
      brand: 'Spilleet',
      writeup:
        'Spileet was created to provide a safe and anonymous environment for users to interact with one another. Users can post messages, share photos, share thoughts and more in order to clear their minds, engage with new friends and make new experiences.',
      lead: 'https://spilleet.com',
      imgs: spillets,
    },
    {
      brand: 'ReniStore',
      writeup:
        'Renistore.com is a simple and secure e-commerce marketplace for your business. You will find all of our products easily searchable, in great sizes and on a very secure platform. Our secure payment system allows us to handle transactions for you that can be settled through escrow without any problems.',
      lead: 'https://reni.store',
      imgs: renistores,
    },
    {
      brand: 'Hotelsoffline',
      writeup:
        'HotelOffline is a hotel management platform that allows hoteliers to create channels and book reservations on behalf of businesses and individuals. Through the automated booking tool, customers are able to receive an instant response, complete their booking quickly and conveniently, and get the best rates.',
      lead: 'https://hotelsoffline.com',
      imgs: hotelofflines,
    },
    {
      brand: 'ReniTrust',
      writeup:
        "ReniTrust is an escrow wallet. ReniTrust is a comprehensive solution to the lack of TRUST in Internet transactions in Africa. We aid online retailers in gaining the trust of prospective clients. We go to considerable measures to ensure that our clients' financial transactions are entirely risk-free and secure",
      lead: 'https://renitrust.com',
      imgs: renitrusts,
    },
    {
      brand: 'Fulazo',
      writeup:
        'Fulazo is a blockchain-based real estate registry that allows users to mint property, crowdfund for projects and invest in properties. It uses the power and potential of blockchain technology to make transactions more secure, transparent, quicker and cheaper.',
      lead: 'https://fulazo.io',
      imgs: fulazos,
    },
    {
      brand: 'JLT',
      writeup:
        'JLT is a business listing platform that allows users to find businesses around them, and patronize those businesses easily. JLT can be used for finding restaurants, bars, coffee shops, graphics designers, developers and more. From the website, it is easy and seamless for users to find the best local business listings near them.',
      lead: '',
      imgs: jlts,
    },
    {
      brand: 'Afcare',
      writeup:
        'Afcare is a tele-medicine product developed to seamlessly attend to vital health issues by monitoring user lifestyle and prescribing proactive measures for healthy living and integrating remote doctor consultation in case of urgent care demand. Users can get real time advice through the app, on their phone, or on any other device that supports Afcare’s technology',
      lead: 'https://afcare.io',
      imgs: afcares,
    },
    {
      brand: 'Solarcredit',
      writeup:
        'Solarcredit is an online Solar sales platform that allows customers purchase high quality solar systems that are suitable for their power demand. Buyers are able to input their load requirement and get recommendations on systems that will conveniently serve their power demand before making their purchase.',
      imgs: solarcredits,
      lead: '',
    },
    {
      brand: 'Solar360',
      writeup:
        'Solar360 is a platform for solar information. The platform bridges the gap between potential solar homeowners and their solar providers. Solar360 has the mission to help people understand their opportunity to become part of the renewable energy revolution. Our goal is to lower barriers to adoption by making it easy and accessible for anybody who wants to do more with solar in their lives.',
      lead: '',
      imgs: solar360s,
    },
    {
      brand: 'Sheda House',
      writeup:
        "Sheda House is a creative community that helps people learn and create. We offer courses in photography, videography, UI/UX, Web development, Data science and Digital marketing and more with an emphasis on business skills such as marketing, branding and technology. We also have a growing community of artists who help each other learn new skills while helping out at Sheda House's events.",
      lead: 'https://shedahouse.com',
      imgs: shedahouses,
    },
  ]


const handleClick = (e , lead) => {
  if (!lead) {
    e.preventDefault() // prevent the default behavior of following the link
    alert('Coming Soon!!!')
  }
  // no else statement needed; if url is truthy, the link will be followed automatically
}



  return (
    <section>
      <div className={`par-portfolio ${darkMode === "dark" ? "dark" : "light"}`}>
        <div className='sub-portfolio allsub'>
          <div className='text-center mb-5 pry-bold-text'>
            <h3
              className='h1-responsive text-center my-4 '
              style={{ fontWeight: '800' }}
            >
              Portfolio
            </h3>

            <p
              className='text-center w-responsive mx-auto mb-5'
              style={{ fontWeight: '400', color: darkMode === "dark" ? "#fff" : "#000" }}
            >
              Throughout the years, we have had the privilege of working with a
              wide range of clients from various industries, helping them to
              streamline their operations, improve their customer experience,
              and achieve their goals. Our portfolio showcases the diverse range
              of solutions we have built, highlighting our expertise and ability
              to deliver innovative and impactful results.
            </p>
          </div>
          <div className='row mx-0 justify-content-between'>
            {product?.map(({ brand, writeup, imgs, lead }, index) => (
              <div className='col-lg-6 mb-lg-4 eachport mb-5' key={index}>
                <a
                  href={lead ? lead : '#'}
                  onClick={(e) => handleClick(e, lead)}
                  target='_blank'
                  rel='noopener noreferrer'
                 className={darkMode === "dark" ? "lightlink-port" : "darklink-port"}
                >
                  <h1 style={{ fontWeight: '700' }} className='mb-3'>{brand}</h1>
                  <img
                    src={imgs}
                    alt={brand}
                    width={'100%'}
                    className='mb-3 portfolio-img'
                  />
                  <p style={{ fontWeight: '400' }}>{writeup}</p>
                </a>
              </div>
            ))}
            {/* <div className='col-lg-6 eachport' style={{ marginTop: '2rem' }}>
              <img src={eclipse} alt='' width={'100%'} className='mb-4' />
              <h1>Eclipse Logistics Service</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className='col-lg-6 eachport'>
              <img src={eclipse} alt='' width={'100%'} className='mb-4' />
              <h1>Eclipse Logistics Service</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className='col-lg-6 eachport' style={{ marginTop: '2rem' }}>
              <img src={reni} alt='' width={'100%'} className='mb-3' />
              <h1>Reni</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
