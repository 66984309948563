import wedo from '../../assets/wedo.jpg'
import wedo1 from '../../assets/wedo1.jpg'
import wedo2 from '../../assets/wedo2.jpg'

const WeDo = ({darkMode}) => {
  const tech = [
    {
      course: 'Web and mobile development',
      writeup:
        'From process automation to internal applications, customized software can have a dramatic impact on your business. Digitally transforming your business using high-quality software development allows you to use your time, money, and resources as efficiently as possible.',
      imgs: wedo,
    },
    {
      course: 'Product Design',
      writeup:
        'Product design is not just making a product look good. It is a process based on understanding the person for whom the product is being created. Our process captures understanding of product vision, research, concepting and prototyping, Sitemaps, Design and prototyping.',
      imgs: wedo1,
    },
    {
      course: 'Blockchain Dev',
      writeup:
        'We have the tools to build NFT Marketplace Development, Blockchain Wallet Development, Smart Contract Development, dApps Development, Hyperledger Based Solution, and Blockchain Technology Consulting',
      imgs: wedo2,
    },
  ]

  return (
    <section>
      <div className={`par-wedo ${darkMode === "dark" ? "dark" : "light"}`}>
        <div className='sub-wedo allsub'>
          <div className='text-center mb-5 pry-bold-text'>
            {/* <h4>
              <span>Reach goals that matter</span>
            </h4> */}
            <h3
              className='h1-responsive text-center my-1 my-lg-4'
              style={{ fontWeight: '800' }}
            >
              What we do
            </h3>

            <p
              className='text-center w-responsive mx-auto mb-5'
              style={{ fontWeight: '400' }}
            >
              We are a software development company and we pride in building
              solutions that are scalable, innovative, and user friendly.
            </p>
          </div>
          {tech?.map(({ course, writeup, imgs }, index) => (
            <div
              className='row mx-0 my-2 my-lg-5 justify-content-between align-items-center otherway odd'
              key={index}
            >
              <div className='col-lg-6 '>
                <h1 className='mb-3' style={{ fontWeight: '700' }}>
                  {course}
                </h1>
                <p className='mb-5' style={{ fontWeight: '400' }}>
                  {writeup}
                </p>
                {/* <div className='row mx-0'>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Duis aute irure dolor in reprehenderit</p>
                </div>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Excepteur sint occaecat</p>
                </div>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Amet consectetur adipiscing elit</p>
                </div>
              </div> */}
              </div>
              <div className='col-lg-5'>
                <img
                  src={imgs}
                  alt=''
                  width={'100%'}
                  style={{ objectFit: 'cover', height: '350px' }}
                />
              </div>
            </div>
          ))}
          {/* second row */}
          {/* <div className='row mx-0 my-5 justify-content-center align-items-center'>
            <div className='col-lg-6'>
              <img src={wedo1} alt='' width={'100%'} />
            </div>
            <div className='col-lg-6'>
              <h1>Training</h1>
              <p className='mb-5'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <div className='row mx-0'>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Duis aute irure dolor in reprehenderit</p>
                </div>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Excepteur sint occaecat</p>
                </div>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Amet consectetur adipiscing elit</p>
                </div>
              </div>
            </div>
          </div> */}
          {/* third row */}
          {/* <div className='row mx-0 my-5 justify-content-center align-items-center otherway'>
            <div className='col-lg-6'>
              <h1>Consultations</h1>
              <p className='mb-5'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <div className='row mx-0'>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Duis aute irure dolor in reprehenderit</p>
                </div>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Excepteur sint occaecat</p>
                </div>
                <div className='col-12 mb-4 d-flex gap-3 justify-content-start align-items-center px-0'>
                  <Tick />
                  <p className='mb-0'>Amet consectetur adipiscing elit</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src={wedo2} alt='' width={'100%'} />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default WeDo

// function Tick() {
//   return (
//     <svg
//       width='26'
//       height='14'
//       viewBox='0 0 12 9'
//       fill='none'
//       xmlns='http://www.w3.org/2000/svg'
//     >
//       <path
//         d='M10.2807 0.280712L3.98771 6.57371L1.69471 4.28071C1.30233 3.90174 0.678627 3.90716 0.292893 4.29289C-0.0928404 4.67863 -0.0982602 5.30233 0.280712 5.69471L3.28071 8.69471C3.67121 9.08509 4.30421 9.08509 4.69471 8.69471L11.6947 1.69471C12.0737 1.30233 12.0683 0.678627 11.6825 0.292893C11.2968 -0.0928404 10.6731 -0.0982602 10.2807 0.280712Z'
//         fill='#48BB78'
//       />
//     </svg>
//   )
// }
