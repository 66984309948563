import home from '../../assets/home.svg'
import home1 from '../../assets/home1.svg'
import home2 from '../../assets/home2.svg'
import home3 from '../../assets/home3.svg'

const Home = ({darkMode}) => {
  
  return (
    <section>
      <div className={`par-home ${darkMode === 'dark' ? 'dark' : 'light'}`}>
        <div className='sub-home text-center py-5'>
          <h1 className='text-center w-responsive mx-auto mb-3'>
            Powering Business Dreams with Tech
          </h1>
          <p className='text-center w-responsive mx-auto mb-5'>
            We’re a global software engineering company creating business
            success stories using technology.
          </p>
          <img src={home} alt='a multiple lines' className='home' />
          <img src={home1} alt='a multiple lines' className='home1' />
          <img src={home2} alt='a multiple lines' className='home2' />
          <img src={home3} alt='a multiple lines' className='home3' />
        </div>
      </div>
    </section>
  )
}

export default Home
