import numbers from '../../assets/numbers.svg'

const About = ({darkMode}) => {

  return (
    <section>
      <div className={`par-about ${darkMode === "dark" ? "dark" : "light"}`}>
        <div className='sub-about allsub'>
          <div className='row mx-0 justify-content-center align-items-center otherway'>
            <div className='col-lg-6'>
              <h1 style={{ fontWeight: '700' }}>About Us</h1>
              <p style={{ fontWeight: '400' }}>
                FireSwitch technology is a digital agency in the city of Ibadan,
                Nigeria. equipped with a team of highly skilled and experienced
                software developers, web developers, product managers, UI/UX
                designers, and UX writers. The team is committed to solving
                business problems and bringing the visions of business owners to
                life.
              </p>
            </div>
            <div className='col-lg-6 mb-lg-0 mb-4'>
              <img src={numbers} alt='group of people' width={'100%'} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
