import team from '../../assets/team.png'

const TeamWork = ({darkMode}) => {
  return (
    <section>
      <div className={`par-teamwork ${darkMode === 'dark' ? 'dark' : 'light'}`}>
        <div className='sub-teamwork allsub'>
          <div className='row mx-0 justify-content-between align-items-center'>
            <div className='col-lg-5 mb-lg-0 mb-4'>
              <img src={team} alt='team' width={'100%'} />
            </div>
            <div className='col-lg-6'>
              <h1>Let’s develop something awesome together.</h1>
              <p>
                We are excited to walk with you on the journey to building a
                product that will truly change our world.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeamWork
